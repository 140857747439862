<template>
  <v-container>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Check-in Event Manager</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text @click="filtersVisible = !filtersVisible">
          <v-icon left>{{ filtersActive ? 'fas fa-filter' : 'fal fa-filter' }}</v-icon>
          Filters
        </v-btn>
        <v-dialog v-model="newEventDialog" width="400" persistent>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text>
              <v-icon left>fal fa-plus-circle</v-icon>
              New Event
            </v-btn>
          </template>
          <v-card>
            <v-card-title>New Event</v-card-title>
            <v-card-text>
              <v-text-field v-model="newTitle" label="Name of Event" outlined></v-text-field>
              <v-combobox v-model="newCategory" :items="categories" label="Event Category" outlined></v-combobox>
              <date-picker v-model="newDate" label="Date of Event" outlined></date-picker>
              <!-- <v-select v-model="newRole" :items="roles" label="Role(s)" outlined multiple></v-select> -->
              <v-checkbox v-model="newScanOut" label="Enable scanning out (will track both in-and-out scans)"></v-checkbox>
            </v-card-text>
            <v-card-actions>
              <v-btn color="success" :disabled="newTitle === '' || newCategory === '' || newDate === ''" text @click="addEvent()">Add Event</v-btn>
              <v-spacer></v-spacer>
              <v-btn text @click="newEventDialog = false">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-card-text v-if="filtersVisible">
        <v-row>
          <v-col>
            <v-select v-model="filterDate" :items="filterDateOptions" label="Future/Past Events"></v-select>
          </v-col>
          <v-col>
            <v-select v-model="filterCategory" :items="categories" label="Event Category" clearable clear-icon="fal fa-times-circle"></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table :headers="headers" :items="events" :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }">
        <template v-slot:item.date="{ item }">{{ item.date.substr(0, 10) }}</template>
        <template v-slot:item.attendees="{ item }">{{ item.attendees.length }}</template>
        <template v-slot:item.action="{ item }">
          <v-btn :to="'/checkin/event/' + item._id" small icon>
            <v-icon>fal fa-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'
export default {
  components: {
    DatePicker: () => import('@/components/forms/inputs/DatePicker')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const roles = computed(() => user.value.roles)
    const categories = ref([])
    const newEventDialog = ref(false)
    const newTitle = ref('')
    const newCategory = ref('')
    const newDate = ref('')
    const newRole = ref([])
    const newScanOut = ref(false)

    const headers = ref([
      { text: 'Title', value: 'title' },
      { text: 'Category', value: 'category' },
      { text: 'Date', value: 'date' },
      { text: 'Attendees', value: 'attendees' },
      { text: 'Action', value: 'action' }
    ])
    const events = ref([])

    onMounted(() => {
      loadEvents()
      root.$feathers.service('checkin/event').find({ query: { $distinct: 'category' } }).then(({ data }) => {
        for (let i = 0; i < data.length; i++) {
          categories.value.push(data[i]._id)
        }
      })
    })

    function loadEvents () {
      let query = { $limit: 50, $sort: { date: -1 } }
      let dt = new Date()
      switch (filterDate.value) {
        case 'future':
          query.date = { $gte: dt }
          break
        case 'past':
          query.date = { $lte: dt }
          break
      }
      if (filterCategory.value != null && filterCategory.value !== '') {
        query.category = filterCategory.value
      }
      root.$feathers.service('checkin/event').find({ query }).then(({ data }) => {
        events.value = data
      })
    }

    function addEvent () {
      const obj = {
        title: newTitle.value,
        category: newCategory.value,
        date: newDate.value,
        createdBy: user.value.username,
        share: [{
          user: user.value,
          role: 'Admin'
        }]
      }
      if (newScanOut.value === true) obj.scanOut = true
      root.$feathers.service('checkin/event').create(obj).then(() => {
        newEventDialog.value = false
        newTitle.value = ''
        newDate.value = ''
        newRole.value = []
        loadEvents()
      })
    }

    const filtersVisible = ref(false)
    const filterDate = ref('')
    const filterCategory = ref('')
    const filterDateOptions = ref([
      { text: 'Show All', value: '' },
      { text: 'Past Events Only', value: 'past' },
      { text: 'Future Events Only', value: 'future' }
    ])
    watch([filterDate, filterCategory], () => { loadEvents() })
    const filtersActive = computed(() => {
      return false
    })

    return {
      user,
      roles,
      categories,
      newEventDialog,
      newTitle,
      newCategory,
      newDate,
      newRole,
      newScanOut,
      headers,
      events,
      addEvent,
      filtersVisible,
      filterDate,
      filterCategory,
      filterDateOptions,
      filtersActive
    }
  }
}
</script>
